// 'use strict';

// export const sep='/';
// export const version: string="22.2.2";  


import { Injectable } from "@angular/core";

@Injectable()
export class Globals{
   companyname: string ="CRESCENT TRADERS";
   address1L1: string="CRESCENT TRADERS";
   address1L2: string="1391, West Second Street,";
   address1L3: string="";
   address1L4: string="Pudukkottai, 622001";
   address2L1: string="CRESCENT TRADERS";
   address2L2: string="1391, West Second Street,";
   address2L3: string="";
   address2L4: string="Pudukkottai, 622001";
   GSTIN: string="33APAPR3389G1ZS";
   mobile1:string="+91 8754144925";
   mobile2:string="+91 8903505000";
   mobile3:string="+91 1234567890";
}
