
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateSuffix' })
export class DateSuffixPipe implements PipeTransform {
   transform(date: string): string {
   // console.log(date);
    if(date!='' && date!=null){
       let suffix = 'th ';
       const prefix: string = date.substring(0, 5);
       var day: string = date.substring(5, 7);
       const remainingdatestr: string = date.substring(7, 50);

       if(day === '01'){
         day='1';
       }
       if(day === '02'){
        day='2';
      }
      if(day === '03'){
        day='3';
      }
     if (day === '1' || day === '21' || day === '31') {
       suffix = 'st ';
     }
     if (day === '2' || day === '22') {
       suffix = 'nd ';
     }
     if (day === '3' || day === '23') {
       suffix = 'rd ';
     }
       const fullDate: string =prefix + day + suffix + remainingdatestr;
       return fullDate;
    }
   } // transform ()
} //  class