import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SignInComponent } from '../../components/sign-in/sign-in.component';
import { SignUpComponent } from '../../components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from '../../components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from '../../components/verify-email/verify-email.component';

import { SecureInnerPagesGuard } from '../guard/secure-inner-pages-guard.guard'
import { HomeComponent } from 'src/app/home/home.component';

import { LabelsComponent } from 'src/app/labels/Labels.component';
import { Labels2Component } from 'src/app/labels2/labels2.component';


const routes: Routes = [
  // { path: '', redirectTo: '/sign-in', pathMatch: 'full'},
  { path: '', redirectTo: '/home', pathMatch: 'full'},



  { path: 'labels', component: LabelsComponent },
  { path: 'labels2', component: Labels2Component },


  { path: 'home', component: HomeComponent },
  { path: 'sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard]},
  // { path: 'sign-up', component: SignUpComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'verify-email-address', component: VerifyEmailComponent, canActivate: [SecureInnerPagesGuard] },

  { path: '**', redirectTo: '/home'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
